<template>
  <span>
    <img src="~Images/star.png" v-for="(star, index) in stars" :key="index" />
    <img src="~Images/star-half.png" v-show="halfStar" />
  </span>
</template>

<script>
export default {
  props: ["number"],
  computed: {
    stars() {
      let floorNumber = Math.floor(this.number);
      if (floorNumber > 0) {
        return [...Array(floorNumber).keys()];
      }

      return [];
    },
    halfStar() {
      let floorNumber = Math.floor(this.number);
      return this.number - floorNumber > 0.3;
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  max-width: 18%;
}
</style>